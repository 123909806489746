import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Select,
  Button,
  Spin,
  Row,
  Col,
  Checkbox,
  Typography,
} from "antd";
import "./tableEdit.css";
import {
  orderType,
  openNotificationWithIcon,
  convertNumber,
  addFormData,
  handleEnter,
} from "../../../util/help";
import axios from "../../../util/api";
import { useSelector, useDispatch } from "react-redux";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
const { Text } = Typography;

const { Option } = Select;

const CreatItemJobOrder = ({ job_d, id, getDataDetail, onFinishDetailJob }) => {
  const [form] = Form.useForm();
  const [state, setState] = useState({ data: [] });
  const [checkNick, setCheckNick] = useState(false);

  const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();

  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  useEffect(() => {
    var newData = job_d;
    for (var i = 0; i < newData.length; i++) {
      newData[i].key = i;
    }
    setState({ data: newData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job_d]);

  const Delete = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...state.data];
      const index = newData.findIndex((item) => key.key === item.key);

      if (index > -1) {
        const params = {
          JOB_NO: id,
          ORDER_TYPE: newData[index].ORDER_TYPE,
          SER_NO: newData[index].SER_NO,
        };
        showLoading();
        axios
          .post("file/job-order/remove-d", addFormData(params))
          .then((res) => {
            if (res.status === 200) {
              if (res.data.success === true) {
                newData.splice(index, 1);
                setState({ ...state, data: newData });
                hideLoading();
                openNotificationWithIcon("success", res.data.msg);
              }
            }
          })
          .catch((err) => console.log(err));
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const [serNo, setSerNo] = useState("");

  const setField = () => {
    form.setFieldsValue({
      ORDER_TYPE: "",
      ORDER_TYPE_NAME: "",
      DESCRIPTION: "",
      PORT_AMT: "",
      INDUSTRY_ZONE_AMT: "",
      NOTE: "",
      THANH_TOAN_MK: setCheckNick(false),
    });
    setSerNo("");
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <>
            <EditOutlined onClick={() => edit(record)} />

            <Popconfirm
              title="Bạn có chắn chắn Delete?"
              onConfirm={() => Delete(record)}
            >
              <DeleteOutlined style={{ marginLeft: 10 }} />
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: "Loại",
      dataIndex: "ORDER_TYPE_NAME",
    },
    {
      title: "Mô tả",
      dataIndex: "DESCRIPTION",
    },
    {
      title: "Port Amt",
      dataIndex: "PORT_AMT",
      render: (text) => convertNumber(parseFloat(text ? text : 0)),
    },
    {
      title: "Industry",
      dataIndex: "INDUSTRY_ZONE_AMT",
      render: (text) => convertNumber(parseFloat(text ? text : 0)),
    },
    {
      title: "Note",
      dataIndex: "NOTE",
      width: "15%",
    },
    {
      title: "Thanh toán",
      dataIndex: "THANH_TOAN_MK",
      render: (text) => <Checkbox checked={text === "Y" ? true : false} />,
    },
    {
      title: "Input",
      dataIndex: "INPUT_USER",
    },
    {
      title: "Edit",
      dataIndex: "MODIFY_USER",
    },
  ];

  const edit = (record) => {
    form.setFieldsValue({
      ORDER_TYPE: record.ORDER_TYPE,
      ORDER_TYPE_NAME: record.ORDER_TYPE_NAME,
      DESCRIPTION: record.DESCRIPTION,
      PORT_AMT: record.PORT_AMT,
      INDUSTRY_ZONE_AMT: record.INDUSTRY_ZONE_AMT,
      THANH_TOAN_MK:
        record.THANH_TOAN_MK === "N" ? setCheckNick(false) : setCheckNick(true),
    });
    setSerNo(record.SER_NO);
  };

  function onFinish(values) {
    const typeTest = orderType.find((i) => i.name === values.ORDER_TYPE_NAME);
    values.ORDER_TYPE = typeTest.type;
    values.THANH_TOAN_MK = checkNick ? "Y" : "N";
    showLoading();
    if (serNo || values.SER_NO) {
      values.SER_NO = serNo;
      values.TYPE = "JOB_ORDER";
      values.JOB_NO = id;
      values.MODIFY_USER = localStorage.getItem("USER_NO");
      values.REV_TYPE = "Y";
      axios
        .post("file/job-order/edit-d", addFormData(values))
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              getDataDetail(id);
              hideLoading();
              setField();
              openNotificationWithIcon("success", "Chỉnh Edit Thành Công");
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      values.TYPE = "JOB_ORDER";
      values.JOB_NO = id;
      values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
      values.INPUT_USER = localStorage.getItem("USER_NO");
      axios
        .post("file/job-order/add-d", addFormData(values))
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              getDataDetail(id);
              hideLoading();
              setField();
              openNotificationWithIcon("success", "Tạo Thành Công");
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }

  function onKeyUp(value) {
    var values = form.getFieldsValue();
    const typeTest = orderType.find((i) => i.name === values.ORDER_TYPE_NAME);
    values.ORDER_TYPE = typeTest.type;
    values.THANH_TOAN_MK = checkNick ? "Y" : "N";
    values.key = state.data.length;
    if (value.key === "Enter" && value.keyCode === 13) {
      // setInputFocus()\
      var newData = state.data;
      newData.push(values);
      setState({ data: [...state.data] });
      setInputFocus();
    }
  }

  function onKeyUpTest(value) {
    if (value.key === "s" && value.keyCode === 83) {
      form.submit();
      // state.data.map(item=>{
      //   onFinish(item)
      // })
    }
  }

  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };

  const [inputRef, setInputFocus] = useFocus();

  return (
    <Fragment>
      <Form
        layout="inline"
        style={{ marginBottom: 12 }}
        onFinish={onFinish}
        form={form}
      >
        <Row gutter={24} style={{ marginBottom: "-1.5em", overflowX: "auto" }}>
          <Col>
            <Form.Item label="Loại" name="ORDER_TYPE_NAME">
              <Select
                style={{ width: 180 }}
                onKeyDown={handleEnter}
                showSearch
                ref={inputRef}
                size="small"
              >
                <Option key="Our Company Pay">Our Company Pay</Option>
                <Option key="Pay In Advance">Pay In Advance</Option>
                <Option key="Trucking Fee">Trucking Fee</Option>
                <Option key="Deposit fee">Cược Cont</Option>
                <Option key="Deposit fix fee">Cược Edit Chữa Cont</Option>
                <Option key="Refund Customer">Refund Customers</Option>
                <Option key="Refund Carriers">Refund Hãng Tàu</Option>
                <Option key="Refund Agent">Refund Đại Lý</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Mô tả" name="DESCRIPTION">
              <Input
                size="small"
                style={{ width: 150 }}
                onKeyDown={handleEnter}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Port Amt" name="PORT_AMT">
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: 100 }}
                size="small"
                onKeyDown={handleEnter}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Industry" name="INDUSTRY_ZONE_AMT">
              <InputNumber
                style={{ width: 100 }}
                onKeyDown={handleEnter}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Note" name="NOTE">
              <Input
                size="small"
                style={{ width: 150 }}
                onKeyDown={handleEnter}
              />
            </Form.Item>
          </Col>
          {(localStorage.getItem("USER_NO") === "PC                  ") |
          (localStorage.getItem("USER_NO") === "QUYNH               ") | (localStorage.getItem("USER_NO") === "VANDY               ")? (
            <Col>
              <Form.Item label="Duyệt thanh toán" name="THANH_TOAN_MK">
                <Checkbox checked={checkNick} onChange={onCheckboxChange} />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Button
          accessKey="s"
          type="primary"
          style={{ marginTop: 20 }}
          onKeyUp={onKeyUpTest}
          onClick={() => form.submit()}
        >
          {serNo ? "Lưu" : "ADD"}
        </Button>
        {serNo ? (
          <Button type="link" onClick={setField} style={{ marginTop: 20 }}>
            Cancel
          </Button>
        ) : null}
      </Form>
      <Table
        dataSource={state.data}
        pagination={false}
        columns={columns}
        bordered
        size="small"
        scroll={{ x: 1100 }}
        summary={(pageData) => {
          var total = 0;
          var total2 = 0;
          pageData.forEach(({ PORT_AMT, INDUSTRY_ZONE_AMT }) => {
            total += parseFloat(PORT_AMT ? PORT_AMT : 0);
            total2 += parseFloat(INDUSTRY_ZONE_AMT ? INDUSTRY_ZONE_AMT: 0);
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell>
                  <Text type="danger">{convertNumber(total)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={5}>
                  <Text type="danger">{convertNumber(total2)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell colSpan={5}>
                  <Text type="danger">{convertNumber(total + total2)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Fragment>
  );
};

export default CreatItemJobOrder;
