import {
  Breadcrumb,
  Spin,
  Form,
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  Button,
} from "antd";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  actDropdownCustomer,
  actDropdownStaff,
} from "../../../actions/actionDropdown";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import axios from "../../../util/api";
import {
  addFormData,
  convertDateTime,
  FetchURL,
  openNotificationWithIcon,
} from "../../../util/help";

function PhieuThuCreate() {
  let history = useHistory();
  let { id } = useParams();
  const [form] = Form.useForm();

  const dropdown = useSelector((state) => state.dropdown);
  const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();
  const FetchCustomer = () => dispatch(actDropdownCustomer());
  const FetchStaff = () => dispatch(actDropdownStaff());
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  useEffect(() => {
    FetchCustomer();
    FetchStaff();
    if (id) {
      showLoading();
      getDataDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onFinish(values) {
    values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
    if (id) {
      values.MODIFY_USER = localStorage.getItem("USER_NO");
      showLoading();
      axios
        .post("payment/receipts/edit", addFormData(values))
        .then((res) => {
          if (res.status === 200) {
            hideLoading();
            openNotificationWithIcon(
              "success",
              `Bạn đã Edit thành công ${values.RECEIPT_NO}`
            );
          }
        })
        .catch((err) => console.log(err));
    } else {
      values.INPUT_USER = localStorage.getItem("USER_NO");
      showLoading();
      axios
        .post("payment/receipts/add", addFormData(values))
        .then((res) => {
          if (res.status === 201) {
            hideLoading();
            history.goBack();
          }
        })
        .catch((err) => console.log(err));
    }
  }

  const setField = (data) => {
    form.setFieldsValue({
      RECEIPT_NO: data.RECEIPT_NO,
      RECEIPT_TYPE: data.RECEIPT_TYPE,
      RECEIPT_DATE: convertDateTime(data.RECEIPT_DATE),
      CUST_NO: data.CUST_NO,
      PNL_NO: data.PNL_NO,
      DOR_NO: data.DOR_NO,
      TOTAL_AMT: data.TOTAL_AMT,
      TRANSFER_FEES: data.TRANSFER_FEES,
      RECEIPT_REASON: data.RECEIPT_REASON,
    });
  };

  const getDataDetail = (id) => {
    fetch(FetchURL + `payment/receipts/des/${id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setField(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>RECEIPTS AND PAYMENT</Breadcrumb.Item>
        <Breadcrumb.Item>Tạo Receipt Information Mới</Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? "Chỉnh Edit Receipt Information" : "Tạo Receipt Information Mới"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Spin size="large" spinning={isLoading}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row>
              <Col>
                <Form.Item label="Receipt No" name="RECEIPT_NO">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Receipt No"
                  name="RECEIPT_TYPE"
                  style={{ marginLeft: 10 }}
                >
                  <Select style={{ width: 150 }}>
                    <Select.Option key="1">Customers</Select.Option>
                    <Select.Option key="2">Cá Nhân</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Date"
                  name="RECEIPT_DATE"
                  style={{ marginLeft: 10 }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Customer" name="CUST_NO">
              <Select
                style={{ width: 300 }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {dropdown.customer.map((item) => {
                  return (
                    <Select.Option key={item.CUST_NO}>
                      {item.CUST_NO + " | " + item.CUST_NAME}
                    </Select.Option>
                  );
                })}
                <Select.Option key="2">Cá Nhân</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Staff" name="PNL_NO">
              <Select
                style={{ width: 300 }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {dropdown.staff.map((item) => {
                  return (
                    <Select.Option key={item.PNL_NO}>
                      {item.PNL_NO + " | " + item.PNL_NAME}
                    </Select.Option>
                  );
                })}
                <Select.Option key="2">Cá Nhân</Select.Option>
              </Select>
            </Form.Item>
            <Row>
              <Col>
                <Form.Item label="Amount" name="TOTAL_AMT">
                  <InputNumber style={{ width: 280 }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Currency"
                  name="DOR_NO"
                  style={{ marginLeft: 10 }}
                >
                  <Select style={{ width: 100 }}>
                    <Select.Option key="VND">VND</Select.Option>
                    <Select.Option key="USD">USD</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Trans Fee" name="TRANSFER_FEES">
              <InputNumber style={{ width: 280 }} />
            </Form.Item>
            <Form.Item label="Notes" name="RECEIPT_REASON">
              <Input.TextArea />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Fragment>
  );
}

export default PhieuThuCreate;
