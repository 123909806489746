import {
  Breadcrumb,
  Form,
  Select,
  Row,
  Col,
  Input,
  Button,
  Table,
  Spin,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  actDropdownCustomer,
  actDropdownPaymentDebit,
} from "../../../actions/actionDropdown";
import { addFormData, convertDateTime } from "../../../util/help";
import axios from "../../../util/api";
import { actShowLoading, actHideLoading } from "../../../actions/actionLoading";

const { Option } = Select;

function TraCuuDuLieu() {
  const [state, setState] = useState([]);

  const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();

  const FetchCustomer = () => dispatch(actDropdownCustomer());
  const FetchPaymentDebit = () => dispatch(actDropdownPaymentDebit());
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  useEffect(() => {
    FetchCustomer();
    FetchPaymentDebit();
  }, []);

  const dropdown = useSelector((state) => state.dropdown);

  const onFinish = (values) => {
    var from = undefined;
    var to = undefined;

    if (values.FROM_DATE && values.TO_DATE) {
      var yearFrom = values.FROM_DATE.slice(0, 4);
      var dayFrom = values.FROM_DATE.slice(5, 7);
      var monthFrom = values.FROM_DATE.slice(8, 10);

      var yearTo = values.TO_DATE.slice(0, 4);
      var dayTo = values.TO_DATE.slice(5, 7);
      var monthTo = values.TO_DATE.slice(8, 10);

      from = yearFrom + monthFrom + dayFrom;
      to = yearTo + monthTo + dayTo;
    }
    // var form = new FormData();
    // form.append("TYPE", values.TYPE);
    // form.append("JOB_NO", values.JOB_NO);
    // form.append("CUST_NO", values.CUST_NO);
    // form.append("TO_DATE", to);
    // form.append("FROM_DATE", from);
    // // api("payment/check-data", "POST", form).then((res) =>
    // //   setState(res.data.data)
    // // );
    values.TO_DATE = to;
    values.FROM_DATE = from;
    showLoading();
    axios
      .post("payment/check-data", addFormData(values))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            setState(res.data.data);
            hideLoading();
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Job No",
      dataIndex: "JOB_NO",
      key: "JOB_NO",
    },
    {
      title: "Mã Customers",
      dataIndex: "CUST_NO",
      key: "CUST_NO",
    },
    {
      title: "Name",
      dataIndex: "CUST_NAME",
      key: "CUST_NAME",
    },
    {
      title: "From",
      dataIndex: "TRANS_FROM",
      key: "TRANS_FROM",
    },
    {
      title: "To",
      dataIndex: "TRANS_TO",
      key: "TRANS_TO",
    },
    {
      title: "Thanh Toán",
      dataIndex: "PAYMENT_CHK",
      key: "PAYMENT_CHK",
      render: (text) => (text === "N" ? "Chưa Thanh Toán" : "Đã Thanh Toán"),
    },
    {
      title: "Ngày Thanh Toán",
      dataIndex: "PAYMENT_DATE",
      key: "PAYMENT_DATE",
      render: (text) => convertDateTime(text),
    },
  ];

  return (
    <Fragment>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>RECEIPTS AND PAYMENT</Breadcrumb.Item>
        <Breadcrumb.Item>Tra Cứu Dữ Liệu</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Tra Cứu Dữ Liệu</Title>
        </div>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={onFinish}
        >
          <Form.Item label="Loại Tra Cứu" name="TYPE">
            <Select style={{ width: 300 }} placeholder="Chọn">
              <Option key="1">Chưa Thanh Toán</Option>
              <Option key="2">Đã Thanh Toán</Option>
              <Option key="3">Tất Cả</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Số Job" name="JOB_NO">
            <Select
              style={{ width: 300 }}
              placeholder="Chọn Job"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {dropdown.paymentDebit.map((item, index) => {
                return (
                  <Option key={index} value={item.JOB_NO}>
                    {item.JOB_NO + " | " + item.CUST_NO}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Mã Customers" name="CUST_NO">
            <Select
              style={{ width: 300 }}
              placeholder="Chọn Mã Customers"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {dropdown.customer.map((item, index) => {
                return (
                  <Option key={index} value={item.CUST_NO}>
                    {item.CUST_NO + " | " + item.CUST_NAME}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="Từ Ngày" name="TO_DATE">
                <Input type="date" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Đến Ngày" name="FROM_DATE">
                <Input type="date" />
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                Tra Dữ Liệu
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Spin size="large" spinning={isLoading}>
          <Table
            dataSource={state}
            rowKey={columns[0].key.toString()}
            columns={columns}
            size="small"
          />
        </Spin>
      </div>
    </Fragment>
  );
}

export default TraCuuDuLieu;
