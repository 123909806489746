import { Button, Col, Form, Input, Row, Select, Typography } from "antd";
import axios from "axios";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actDropdownInformationUser } from "../../../actions/actionDropdown";
import { addFormData } from "../../../util/help";

const { Title } = Typography;
const { Option } = Select;
export default function ThongKeJobOrder() {
  const dropdown = useSelector((state) => state.dropdown);
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  function onFinish(values) {
    var yearFrom = values.fromdate.slice(0, 4);
    var dayFrom = values.fromdate.slice(5, 7);
    var monthFrom = values.fromdate.slice(8, 10);

    var yearTo = values.todate.slice(0, 4);
    var dayTo = values.todate.slice(5, 7);
    var monthTo = values.todate.slice(8, 10);

    var from = yearFrom + dayFrom + monthFrom;
    var to = yearTo + dayTo + monthTo;
    window.open(
      `https://cam-api.ihtvn.com/api/v1/print/payment/job-order/type=${values.type}&custno=${values.custno}&person=${values.person}&fromdate=${from}&todate=${to}`
    );
  }

  function exportExcel() {
    var values = form.getFieldsValue();
    var yearFrom = values.fromdate.slice(0, 4);
    var dayFrom = values.fromdate.slice(5, 7);
    var monthFrom = values.fromdate.slice(8, 10);

    var yearTo = values.todate.slice(0, 4);
    var dayTo = values.todate.slice(5, 7);
    var monthTo = values.todate.slice(8, 10);

    var from = yearFrom + dayFrom + monthFrom;
    var to = yearTo + dayTo + monthTo;
    values.fromdate = from;
    values.todate = to;
    axios
      .post("export/payment/job-order", addFormData(values))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.url);
        }
      })
      .catch((err) => console.log(err));
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actDropdownInformationUser());
  }, []);


  return (
    <Fragment>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <Title style={{ color: "#1890ff" }}>Thống Kê Số Job Order</Title>
      </div>
      <Form
        onFinish={onFinish}
        form={form}
        size="small"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 6 }}
      >
        <Form.Item label="Loại" name="type" initialValue="truck_fee">
          <Select>
            <Option key="truck_fee">Trucking Fee</Option>
            <Option key="have_not_debit_note">Chưa có Debit Note</Option>
            <Option key="unpaid_cont">Cược cont(Chưa duyệt)</Option>
            <Option key="paid_cont">Cược cont(Đã duyệt)</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Customer" name="custno">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dropdown.customer.map((item) => {
              return (
                <Option key={item.CUST_NO}>
                  {item.CUST_NO + " | " + item.CUST_NAME}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Person" name="person">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dropdown.information.map((item) => {
              return <Option key={item.CUST_NO}>{item.USER_NO}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Từ Ngày" name="fromdate">
          <Input type="date" />
        </Form.Item>
        <Form.Item label="Đến Ngày" name="todate">
          <Input type="date" />
        </Form.Item>
        <Row>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                In
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => exportExcel()}
              style={{ marginLeft: 20 }}
            >
              Xuất Excel
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
}
