import React, { Fragment, useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Transfer,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  actDropdownCustomer,
  actDropdownListJobHasDebit,
  actDropdownStaff,
} from "../../../actions/actionDropdown";
import Axios from "axios";
import axios from "axios";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { formatColumn } from "../../../util/help";
import readXlsxFile from "read-excel-file";

const { Option } = Select;

export default function PhieuYeuCauThanhToan() {
  //dispatch
  const dispatch = useDispatch();
  const getListJobHasD = () => dispatch(actDropdownListJobHasDebit());
  const getListStaff = () => dispatch(actDropdownStaff());
  const getListCusomer = () => dispatch(actDropdownCustomer());

  //selector
  const dropdown = useSelector((state) => state.dropdown);

  const isLoading = useSelector((state) => state.isLoading);

  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  const [state, setState] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [value, setValue] = useState(undefined);
  const key = "updatable";

  useEffect(() => {
    getListJobHasD();
    getListStaff();
    getListCusomer();
  }, []);

  const [form] = Form.useForm();

  function onFinish(values) {
    var custno = [];
    custno.push(values.CUST_NO);
    values.custno = custno;
    if (values.type === "customer") {
      var newData = [];
      for (let index = 0; index < values.KEY_JOB.length; index++) {
        var element = values.KEY_JOB[index];
        newData.push(state.find((item) => item.key === element).JOB_NO);
      }
      values.jobno = newData;
    } else if (values.type === "job") {
      var JOB_NO = [];
      JOB_NO.push(values.JOB_NO);
      values.jobno = JOB_NO;
    }
    //https://iht-cors-server.herokuapp.com/http://api-job.ihtvn.com/api/v1/print/payment/debit-note/
    axios.post(
      "https://cors-iht.onrender.com/https://cam-api.ihtvn.com/api/v1/print/payment/debit-note",
      values
    )
      .then((res) => {
        console.log(res)
        var newWindow = window.open("", "new window");
        newWindow.document.write(res.data);
      })
      .catch((err) => console.log(err));
  }

  function onExcel() {
    var values = form.getFieldsValue();
    var custno = [];
    var formData = new FormData();
    custno.push(values.CUST_NO);
    values.custno = custno;
    if (values.type === "customer") {
      var newData = [];
      for (let index = 0; index < values.KEY_JOB.length; index++) {
        var element = values.KEY_JOB[index];
        newData.push(state.find((item) => item.key === element).JOB_NO);
      }
      values.jobno = newData;
    } else if (values.type === "job") {
      var JOB_NO = [];
      JOB_NO.push(values.JOB_NO);
      values.jobno = JOB_NO;
    }
    for (var i in values.jobno) {
      formData.append("jobno[]", values.jobno[i]);
    }
    for (var i in values.custno) {
      formData.append("custno[]", values.custno[i]);
    }
    formData.append("person", values.person);
    formData.append("phone", values.phone);
    formData.append("type", values.type);
    formData.append("bankno", values.bankno);
    formData.append("fromdate", values.fromdate ? values.fromdate : null);
    formData.append("todate", values.todate ? values.todate : null);
    formData.append("debittype", values.debittype ? values.debittype : null);
    showLoading();
    axios
      .post("export/payment/debit-note", formData)
      .then((res) => {
        if (res.status === 200) {
          hideLoading();
          window.open(res.data.url);
        }
      })
      .catch((err) => console.log(err));
  }

  function onExcelNew() {
    var values = form.getFieldsValue();
    var custno = [];
    var formData = new FormData();
    custno.push(values.CUST_NO);
    values.custno = custno;
    values.jobno = newData;
    var newData = [];
    for (let index = 0; index < values.KEY_JOB.length; index++) {
      var element = values.KEY_JOB[index];
      newData.push(state.find((item) => item.key === element).JOB_NO);
    }
    values.jobno = newData;
    for (var i in values.jobno) {
      formData.append("jobno[]", values.jobno[i]);
    }
    for (var i in values.custno) {
      formData.append("custno[]", values.custno[i]);
    }
    formData.append("person", values.person);
    formData.append("phone", values.phone);
    formData.append("type", "customer_new");
    formData.append("bankno", values.bankno);
    formData.append("fromdate", values.fromdate ? values.fromdate : null);
    formData.append("todate", values.todate ? values.todate : null);
    formData.append("debittype", values.debittype ? values.debittype : null);
    showLoading();
    axios
      .post("export/payment/debit-note", formData)
      .then((res) => {
        if (res.status === 200) {
          hideLoading();
          window.open(res.data.url);
        }
      })
      .catch((err) => console.log(err));
  }

  function onChange(value) {
    var formData = new FormData();
    formData.append("custno", value);
    axios
      .post("payment/debit-note/list-cust-job", formData)
      .then((res) => {
        if (res.status === 200) {
          var newData = res.data.data;
          for (var i = 0; i < newData.length; i++) {
            newData[i].key = i.toString();
          }
          setState(newData);
        }
      })
      .catch((err) => console.log(err));
  }

  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const onChangeTran = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleSearch = (value) => {
    if (value) {
      setTimeout(function () {
        axios
          .get(`payment/debit-note/search/type=job_no&value=${value}`)
          .then((res) => setDataSearch(res.data.data))
          .catch((err) => console.log(err));
      }, 1500);
    }
  };

  const handleChange = (value) => {
    setValue(value);
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    var fmt = "#,##0";
    const range = XLSX.utils.decode_range(ws["!ref"]);
    var colNum = XLSX.utils.decode_col("Q");
    var colNum2 = XLSX.utils.decode_col("R");
    var colNum3 = XLSX.utils.decode_col("S");
    formatColumn(ws, colNum, range, fmt);
    formatColumn(ws, colNum2, range, fmt);
    formatColumn(ws, colNum3, range, fmt);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportExYen = () => {
    message.loading({ content: "Loading...", key });
    var values = form.getFieldsValue();
    var newData = [];
    for (let index = 0; index < values.KEY_JOB.length; index++) {
      var element = values.KEY_JOB[index];
      newData.push(state.find((item) => item.key === element).JOB_NO);
    }
    values.JOB_NO = newData;
    Axios.post(
      "http://123.30.249.224:3001/api/reports-basic/excel-phieu-yeu-cau-thanh-toan",
      values
    )
      .then((res) => {
        exportToCSV(
          res.data.data,
          `Phieu_Yeu_Cau_Thanh_Toan_${values.CUST_NO}`
        );
        if (res.status === 200)
          message.success({ content: "Done!", key, duration: 2 });
      })
      .catch((err) => console.log(err));
  };
  const schema = {
    JOB_NO: {
      prop: "JOB_NO",
      type: String,
    },
    CUST_NO: {
      prop: "CUST_NO",
      type: String,
    },
  };

  const exportExYenFromEx = (input) => {
    message.loading({ content: "Loading...", key });
    readXlsxFile(input.target.files[0], { schema }).then(({ rows, errors }) => {
      var arr = [];
      for (var i = 0; i < rows.length; i++) {
        arr.push(rows[i].JOB_NO);
      }
      const data = { JOB_NO: arr, CUST_NO: rows[0].CUST_NO };
      Axios.post(
        "http://123.30.249.224:3001/api/reports-basic/excel-phieu-yeu-cau-thanh-toan",
        data
      )
        .then((res) => {
          exportToCSV(
            res.data.data,
            `Phieu_Yeu_Cau_Thanh_Toan_${data.CUST_NO}`
          );
          if (res.status === 200)
            message.success({ content: "Done!", key, duration: 2 });
        })
        .catch((err) => console.log(err));
    });
  };

  return (
    <Fragment>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <Title style={{ color: "#1890ff" }}>Phiếu yêu cầu thanh toán</Title>
      </div>
      <Spin spinning={isLoading}>
        <Form
          onFinish={onFinish}
          form={form}
          size="small"
          // layout="horizontal"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
        >
          <Form.Item label="Loại" name="type" initialValue="job">
            <Select style={{ maxWidth: 250 }}>
              <Option key={"job"}>Job No</Option>
              <Option key="customer">Customer</Option>
              <Option key="debit_date">Debit Note</Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue("type") === "job" ? (
                <Form.Item label="Job No" name="JOB_NO">
                  <Select
                    style={{ maxWidth: 250 }}
                    showSearch
                    value={value}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(value) => handleSearch(value)}
                    onChange={(value) => handleChange(value)}
                    notFoundContent={null}
                  >
                    {dataSearch.map((item) => {
                      return <Option key={item.JOB_NO}>{item.JOB_NO}</Option>;
                    })}
                  </Select>
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue("type") === "customer" ? (
                <Form.Item label="Customer" name="CUST_NO">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ maxWidth: 300 }}
                    onChange={onChange}
                  >
                    {dropdown.customer.map((item) => {
                      return (
                        <Option key={item.CUST_NO}>
                          {item.CUST_NO + " | " + item.CUST_NAME}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            {({ getFieldValue }) => {
              return (getFieldValue("type") === "customer") |
                (getFieldValue("type") === "job") ? (
                <>
                  <Form.Item label="Contact person" name="person">
                    <Select
                      style={{ maxWidth: 250 }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dropdown.staff.map((staff) => {
                        return (
                          <Option key={staff.PNL_NO}>
                            {staff.PNL_NO + " | " + staff.PNL_NAME}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Phone Number" name="phone">
                    <Input style={{ maxWidth: 250 }} />
                  </Form.Item>
                </>
              ) : null;
            }}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue("type") === "customer" ? (
                <Form.Item name="KEY_JOB">
                  <Transfer
                    dataSource={state}
                    listStyle={{
                      width: 400,
                      height: 300,
                    }}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={onChangeTran}
                    onSelectChange={onSelectChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.JOB_NO.toLowerCase().indexOf(
                        input.toLowerCase()
                      ) >= 0
                    }
                    // onScroll={onScroll}
                    pagination
                    render={(item) => `${item.JOB_NO} -  ${item.CUST_NAME}`}
                  />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue("type") === "debit_date" ? (
                <>
                  <Row gutter={24}>
                    <Col span={6}>
                      <Form.Item label="Ngày" name="fromdate">
                        <Input type="date" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="todate">
                        <Input type="date" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* our_company_pay, pay_in_advance, all */}
                  <Form.Item label="Loại Debit" name="debittype">
                    <Select style={{ maxWidth: 300 }}>
                      <Option key="our_company_pay">Our Company Pay</Option>
                      <Option key="pay_in_advance">Pay In Advance</Option>
                      <Option key="all">
                        Our Company Pay vs Pay In Advance
                      </Option>
                    </Select>
                  </Form.Item>
                </>
              ) : null;
            }}
          </Form.Item>
          <Form.Item label="Chọn Ngân Hàng" name="bankno">
            <Select style={{ maxWidth: 250 }}>
              <Option key="ABA">ABA</Option>              
            </Select>
          </Form.Item>
          <Row>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  In
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  style={{ marginLeft: 20 }}
                  onClick={onExcel}
                >
                  Xuất excel
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  style={{ marginLeft: 20 }}
                  onClick={onExcelNew}
                >
                  Xuất excel New
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button style={{ marginLeft: 20 }} onClick={exportExYen}>
                  Xuất excel Yen
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <input
                  style={{ marginLeft: 20 }}
                  type="file"
                  onChange={(input) => exportExYenFromEx(input)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Fragment>
  );
}
