import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Spin,
  Table,
  Button,
  Popconfirm,
  Pagination,
  Tabs,
} from "antd";
import Title from "antd/lib/typography/Title";
import { useSelector, useDispatch } from "react-redux";
import {
  addFormData,
  convertDateTime,
  FetchURL,
  openNotificationWithIcon,
} from "../../../util/help";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import axios from "../../../util/api";
import {
  fetchApproveJobOrder,
  fetchPendingJobOrder,
} from "../../../actions/actionDuỵetJobOrder";

const { TabPane } = Tabs;

function DuyetJobOrder() {
  const [state, setState] = useState({ total_page: 1 });

  const isLoading = useSelector((state) => state.isLoading);
  const duyetjoborder = useSelector((state) => state.duyetjoborder);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const Fetch = (data) => dispatch(fetchPendingJobOrder(data));
  const Fetch2 = (data) => dispatch(fetchApproveJobOrder(data));

  useEffect(() => {
    onChangePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePage = (page) => {
    var newPage = 1;
    if (page) {
      newPage = page;
    }
    showLoading();
    fetch(FetchURL + `file/approved/list-pending/page=${newPage}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Fetch(data.data);
          setState({ total_page: data.total_page });
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  const onChangePageApprove = (page) => {
    var newPage = 1;
    if (page) {
      newPage = page;
    }
    showLoading();
    fetch(FetchURL + `file/approved/list-approved/page=${newPage}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Fetch2(data.data);
          setState({ total_page: data.total_page });
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  const Duyet = (JOB_NO) => {
    showLoading();
    const params = {
      JOB_NO: JOB_NO,
    };
    axios
      .post("file/approved", addFormData(params))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            openNotificationWithIcon("success", `${JOB_NO} đã được duyệt`);
            hideLoading();
          }
        }
      })
      .catch((err) => {
        hideLoading();
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Job No",
      dataIndex: "JOB_NO",
      key: "JOB_NO",
    },
    {
      title: "Công Ty",
      dataIndex: "CUST_NAME",
      key: "CUST_NAME",
    },
    {
      title: "Order Date",
      dataIndex: "ORDER_DATE",
      key: "ORDER_DATE",
      render: (text) => convertDateTime(text),
    },
    {
      render: (text, record) => (
        <>
          {record.CHK_MK === "N" ? (
            <Popconfirm
              title="Bạn có chắn chắn?"
              onConfirm={() => Duyet(record.JOB_NO)}
            >
              <Button type="primary">Duyệt</Button>
            </Popconfirm>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>JOB CARDS MANAGEMENT</Breadcrumb.Item>
        <Breadcrumb.Item>Duyệt Job Order</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Duyệt Job Order</Title>
        </div>

        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"large"}
          onTabClick={(e) =>
            e === "2" ? onChangePageApprove() : onChangePage()
          }
        >
          <TabPane tab="Pending" key="1">
            <Table
              dataSource={duyetjoborder}
              columns={columns}
              rowKey={columns[0].key.toString()}
              pagination={false}
            />
            <Pagination
              total={state.total_page}
              defaultCurrent={1}
              onChange={onChangePage}
              style={{ marginTop: 20 }}
            />
          </TabPane>
          <TabPane tab="Approve" key="2">
            <Table
              dataSource={duyetjoborder}
              columns={columns}
              rowKey={columns[0].key.toString()}
              pagination={false}
            />{" "}
            <Pagination
              total={state.total_page}
              defaultCurrent={1}
              onChange={onChangePageApprove}
              style={{ marginTop: 20 }}
            />
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  );
}

export default DuyetJobOrder;
