import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { actDropdownListPhieuChi } from "../../../actions/actionDropdown";
import PhieuYeuCauThanhToan from "./PhieuYeuCauThanhToan";
import axios from "../../../util/api";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import ThongKeSoJob from "./ThongKeSoJob";
import BaoCaoLoiNhuan from "./BaoCaoLoiNhuan";
import { UploadOutlined } from '@ant-design/icons';
import Axios from "axios";
import ThongKeJobOrder from "./ThongKeJobOrder";
import readXlsxFile from "read-excel-file";
import FormCambodia from "./FormCambodia";

const { Option } = Select;
function BaoBieuThuChi() {
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [progress, setProgress] = useState(0);

  const dropdown = useSelector((state) => state.dropdown);


  const dispatch = useDispatch();
  const getListPhieuChi = () => dispatch(actDropdownListPhieuChi());

  const isLoading = useSelector((state) => state.isLoading);

  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  useEffect(() => {
    getListPhieuChi();
  }, []);

  function onFinish(values) {
    var formData = new FormData();
    for (var i in values.advanceno) {
      formData.append("advanceno[]", values.advanceno[i]);
    }
    showLoading();
    axios
      .post("print/payment/advance/replenishment-withdrawal-payment", formData)
      .then((res) => {
        hideLoading();
        var newWindow = window.open("", "new window");
        newWindow.document.write(res.data);
      })
      .catch((err) => console.log(err));
  }
  const [form] = Form.useForm();

  function onExcel() {
    var values = form.getFieldsValue();
    var formData = new FormData();
    for (var i in values.advanceno) {
      formData.append("advanceno[]", values.advanceno[i]);
    }
    showLoading();
    axios
      .post("export/payment/advance/replenishment-withdrawal-payment", formData)
      .then((res) => {
        hideLoading();
        window.open(res.data.url);
      })
      .catch((err) => console.log(err));
  }

  const schema = {
    LENDER: {
      // JSON object property name.
      prop: "LENDER",
      type: String,
    }
  }

  const uploadFile = async options => {
    const { onSuccess, onError, file, onProgress } = options;
    // var formData = new FormData()
    // formData.append('file', file)
    // try {
    //   const res = await Axios.post(
    //     "http://api-job.ihtvn.com/api/v1/import/payment",
    //     formData
    //   );
    //   if (res.status === 200) {
    //     onSuccess("Ok");
    //     window.open(res.data.url)
    //   }
    // } catch (err) {
    //   console.log("Eroor: ", err);
    //   const error = new Error("Some error")
    //   onError({ err });
    // }
    readXlsxFile(file, { schema }).then(({ rows, errors }) => {
      var formData = new FormData();
      for (var i in rows) {
        formData.append("advanceno[]", rows[i].LENDER);
      }
      showLoading();
      axios
        .post("export/payment/advance/replenishment-withdrawal-payment", formData)
        .then((res) => {
          hideLoading();
          window.open(res.data.url);
        })
        .catch((err) => console.log(err));
    });
  }

  const handleOnChange = ({ file, fileList, event }) => {
    // console.log(file, fileList, event);
    //Using Hooks to update the state to the current filelist
    setDefaultFileList(fileList);
    //filelist - [{uid: "-1",url:'Some url to image'}]
  };

  return (
    <Fragment>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>RECEIPTS AND PAYMENT</Breadcrumb.Item>
        <Breadcrumb.Item>RECEIPTS AND PAYMENT REPORTS</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>
            Thống Kê Phiếu Bù và Phiếu Trả
          </Title>
        </div>
        <Spin spinning={isLoading}>
          <Form onFinish={onFinish} form={form} size='small'>
            {/* <Form.Item>
              <Transfer
                dataSource={dropdown.listPhieuChi}
                listStyle={{
                  width: 400,
                  height: 300,
                }}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChangeTran}
                onSelectChange={onSelectChange}
                showSearch
              />
            </Form.Item> */}

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="advanceno">
                  <Select mode="tags" placeholder="Chọn">
                    {dropdown.listPhieuChi.map((item) => {
                      return (
                        <Option key={item.LENDER_NO}>{`${item.LENDER_NO} | ${item.JOB_NO ? item.JOB_NO : ""
                          } | ${item.LENDER_NAME}`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  In
                </Button>
                <Button
                  type="primary"
                  onClick={() => onExcel()}
                  style={{ marginLeft: 20 }}
                >
                  Xuất excel
                </Button>
                <Upload customRequest={uploadFile} onChange={handleOnChange} defaultFileList={defaultFileList}>
                  <Button
                    style={{ marginLeft: 20 }}
                    icon={<UploadOutlined />}
                  >
                    Gửi file excel lên
                </Button>
                </Upload>
              </Col>
            </Row>
          </Form>
        </Spin>
        <PhieuYeuCauThanhToan />
        <ThongKeSoJob />
        <BaoCaoLoiNhuan />
        <ThongKeJobOrder />
        <FormCambodia />
      </div>
    </Fragment>
  );
}

export default BaoBieuThuChi;
